import React from "react";

import "../../../styles/pages/pages.css";
import "../../../styles/pages/montsaintmichel.css";
import Presentation from "./Presentation";
import Journee1 from "./Journee1";
import Journee2 from "./Journee2";
import Journee3 from "./Journee3";
import Journee4 from "./Journee4";
import Journee5 from "./Journee5";
import Journee6 from "./Journee6";
import Journee7 from "./Journee7";
import Journee8 from "./Journee8";
import Journee9 from "./Journee9";

export default class MontSaintMichel extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="a-page" id="montsaintmichel-page">
                <h1>Vélo-rando au Mt Saint-Michel avec Oussama.</h1>
                <Presentation />
            </div>
        );
    }

}