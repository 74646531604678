import React from "react";

import "../../../styles/pages/pages.css";
import "../../../styles/pages/danceforscore.css";

const danceforscore_archive = "https://datas.blog.mpaty.fr/builds/danceforscore_archive.rar";
const danceforscore_ex_2 = "https://datas.blog.mpaty.fr/images/danceforscore_ex_2.png";

export default class DanceForScore extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="a-page" id="danceforscore-page">
                <div className="left-side">
                    <h2>Installation</h2>
                    <p>Tout d'abord <a href={danceforscore_archive} >télécharger l'archive du jeu.</a></p>
                    <p>Décompresser l'archive {"(avec winrar)"}.</p>
                    <p>Lancer <strong>DanceForScore.exe</strong></p>
                    <h2>Commandes</h2>
                    <p><strong>Z, Q, S, D</strong> : Déplacements</p>
                    <p><strong>Espace</strong> : Changer le mode du char.</p>

                </div>
                <div className="main-container">
                    <h1>Dance for Score</h1>
                    <h2>Jeu Lua Love2D</h2>
                    <a href="https://blackhole59.itch.io/danceforscore" > {">>>"} voir page itch.io</a>
                    <p>
                        Dansez ou mourrez ! Les zombies courrent après vous, tentez de leurs échapper pour augmenter votre score.
                        Le "HIGH SCORE" est sauvegardé, réussirez-vous à faire mieux ? 
                    </p>
                    <p>
                        Ce jeu est ma version d'un cours en lua de Gamecodeur.fr
                        <ul>
                            <li>Music : Etienne de Crécy "Cut The Crap"</li>
                            <li>Graphismes : Ecole en ligne Gamecodeur.</li>
                        </ul>
                    </p>
                    <img loading="lazy" src={danceforscore_ex_2} />
                </div>

            </div>
        );
    }

}