import React from "react";

import "../../../styles/pages/pages.css";
import "../../../styles/pages/cyclix.css";

const build_14_10_2022 = "https://datas.blog.mpaty.fr/builds/cyclix_app_debug_14_10_2022.apk";
const build_29_08_2022 = "https://datas.blog.mpaty.fr/builds/cyclix_app_debug_29_08_2022.apk";
const build_27_08_2022 = "https://datas.blog.mpaty.fr/builds/cyclix_app_debug_27_08_2022.apk";
const build_25_08_2022 = "https://datas.blog.mpaty.fr/builds/cyclix_app_debug_25_08_2022.apk";
const cyclix_ex_1 = "https://datas.blog.mpaty.fr/images/cyclix_ex_1.jpg";
const cyclix_ex_2 = "https://datas.blog.mpaty.fr/images/cyclix_ex_2.jpg";
const cyclix_ex_3 = "https://datas.blog.mpaty.fr/images/cyclix_ex_3.jpg";

export default class Cyclix extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="a-page" id="cyclix-page">
                <h1>Application pour cyclistes, sportifs comme amateurs.</h1>
                <div id="left-side">
                    <h2>Historique des builds</h2>
                    <div className="a-build">
                        <div>
                            <div className="date">14/10/2022</div>
                            <div className="plateform">Android</div>
                        </div>
                        <div className="name">cyclix_app_debug</div>
                        <div className="link"><a href={build_14_10_2022}>APK</a></div>
                    </div>
                    <div className="a-build">
                        <div>
                            <div className="date">29/08/2022</div>
                            <div className="plateform">Android</div>
                        </div>
                        <div className="name">cyclix_app_debug</div>
                        <div className="link"><a href={build_29_08_2022}>APK</a></div>
                    </div>
                    <div className="a-build">
                        <div>
                            <div className="date">27/08/2022</div>
                            <div className="plateform">Android</div>
                        </div>
                        <div className="name">cyclix_app_debug</div>
                        <div className="link"><a href={build_27_08_2022}>APK</a></div>
                    </div>
                    <div className="a-build">
                        <div>
                            <div className="date">25/08/2022</div>
                            <div className="plateform">Android</div>
                        </div>
                        <div className="name">cyclix_app_debug</div>
                        <div className="link"><a href={build_25_08_2022}>APK</a></div>
                    </div>
                </div>
                <div id="main-container">
                    <h3>Companion App for Cyclists to visualize your progressions and find your way !</h3>
                    <p>Trace your route into the ergonomic map, easy to navigate during cycling sessions. Track your wheels with the gps datas. You can add each of your outing, it's your route notebook !</p>
                    <p>Calculate your efforts and see how many calories you burn on each of your outing, kilometers, time, altitude, average speed, and others parameters. See your progression into the graph.</p>
                    <p>Analyze your performances to anticipate the futur of your sport, to give you acurate overview of new itineraries.</p>
                    <p>The application fit to your convenience with the complete parameters for each features</p>
                    <p>Cyclix is now in development, all functionalities are not yet implemented.</p>
                    <p>Thanks to you !</p>
                    <p>Steer your sport.</p>
                </div>
                <div id="right-imagery">
                    <img loading="lazy" className="cyclix-screen-image" src={cyclix_ex_2} />
                    <img loading="lazy" className="cyclix-screen-image" src={cyclix_ex_3} />   
                    <img loading="lazy" className="cyclix-screen-image" src={cyclix_ex_1} />
                </div>
            </div>
        );
    }

}