import React from "react";

import "../../../styles/pages/pages.css";
import "../../../styles/pages/assembler_interpreter.css";

const assembler_interpreter_sujet = "https://datas.blog.mpaty.fr/documents/labyrinthepoo2022_sujet.pdf";
const assembler_interpreter_readme_pdf = "https://datas.blog.mpaty.fr/images/assemblerinterpreter/readme.pdf";
const assembler_interpreter_readme_html = "https://datas.blog.mpaty.fr/images/assemblerinterpreter/readme.html";
const processor_header_jpeg = "https://datas.blog.mpaty.fr/images/assemblerinterpreter/processor_header.jpeg";

export default class AssemblerInterpreter extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="a-page" id="assembler_interpreter-page">
                <div className="main-container">
                    <iframe src={assembler_interpreter_readme_html}/>
                    <div id="gitlab-link">
                        <a href="https://gitlab.com/matthias.p/assembler-interpreter-simulation-of-processor">Dépot Gitlab</a>
                    </div>
                    <div id="processor-header"><img src={processor_header_jpeg}></img></div>
                </div>

            </div>
        );
    }

}