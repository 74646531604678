import React from "react";
import { Link } from "react-router-dom";

const img_1 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/1.jpg";
const img_2 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/2.jpg";
const img_3 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/3.jpg";
const img_4 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/4.jpg";
const img_5 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/5.jpg";
const img_6 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/6.jpg";
const img_7 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/7.jpg";
const img_8 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/8.jpg";
const img_9 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/9.jpg";
const img_10 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/10.jpg";
const img_11 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/11.jpg";
const img_12 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/12.jpg";
const img_13 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/13.jpg";
const img_14 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/14.jpg";
const img_15 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/15.jpg";
const img_16 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/16.jpg";
const img_17 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/17.jpg";
const img_18 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/18.jpg";
const img_19 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/19.jpg";
const img_20 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/20.jpg";
const img_21 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/21.jpg";
const img_22 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/22.jpg";
const img_23 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/23.jpg";
const img_24 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/24.jpg";
const img_25 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/25.jpg";
const img_26 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/26.jpg";
const img_27 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/27.jpg";
const img_28 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/28.jpg";
const img_29 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/29.jpg";
const img_30 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/30.jpg";
const img_31 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/31.jpg";
const img_32 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/32.jpg";
const img_33 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/33.jpg";
const img_34 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/34.jpg";
const img_35 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/35.jpg";
const img_36 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/36.jpg";
const img_37 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/37.jpg";
const img_38 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/38.jpg";
const img_39 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/39.jpg";
const img_40 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/40.jpg";
const img_41 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/41.jpg";
const img_42 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/42.jpg";
const img_43 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/43.jpg";
const img_44 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/44.jpg";
const img_45 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/45.jpg";
const img_46 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/46.jpg";
const img_47 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/47.jpg";
const img_48 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/48.jpg";
const img_49 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/49.jpg";
const img_50 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/50.jpg";
const img_51 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/51.jpg";
const img_52 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/52.jpg";
const img_53 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/53.jpg";
const img_54 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/54.jpg";
const img_55 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/55.jpg";
const img_56 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/56.jpg";
const img_57 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/57.jpg";
const img_58 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/58.jpg";
const img_59 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/59.jpg";
const img_60 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j4/60.jpg";


export default class Journee4 extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="montsaintmichel-j-page" id="montsaintmichel-j4-page">
                <h1>Dimanche - Etape de Dieppe</h1>
                <h2>Temps gris</h2>
                <table><tr><th>Distance (km)</th><th>Temps en route</th><th>Horaires</th></tr><tr><td>86.308</td><td>---</td><td>10h15-12h-13h45-19h45</td></tr></table>
                <p>On continue notre route cette fois vers Dieppe.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_1} />
                    <img loading="lazy" src={img_2} />
                    <img loading="lazy" src={img_3} />
                    <img loading="lazy" src={img_4} />
                    <img loading="lazy" src={img_5} />
                    <img loading="lazy" src={img_6} />
                    <img loading="lazy" src={img_7} />
                    <img loading="lazy" src={img_8} />
                    <img loading="lazy" src={img_9} />
                </div>                
                <p>C'est une jolie ville portuaire, bien plus rustique que Le Treport. </p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_10} />
                    <img loading="lazy" src={img_11} />
                    <img loading="lazy" src={img_12} />
                    <img loading="lazy" src={img_13} />
                    <img loading="lazy" src={img_14} />
                    <img loading="lazy" src={img_15} />
                    <img loading="lazy" src={img_16} />
                    <img loading="lazy" src={img_17} />
                </div>                
                <p>Voici l'église Saint-Jacques :</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_18} />
                    <img loading="lazy" src={img_19} />
                    <img loading="lazy" src={img_20} />
                    <img loading="lazy" src={img_21} />
                    <img loading="lazy" src={img_22} />
                    <img loading="lazy" src={img_23} />
                    <img loading="lazy" src={img_24} />
                </div>                
                <p>Le temps de visiter la ville qu'il est déjà midi, on casse la croûte dans un parc mémorial de la seconde guerre mondiale du Canada.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_25} />
                    <img loading="lazy" src={img_26} />
                    <img loading="lazy" src={img_27} />
                    <img loading="lazy" src={img_28} />
                    <img loading="lazy" src={img_29} />
                    <img loading="lazy" src={img_30} />
                    <img loading="lazy" src={img_31} />
                    <img loading="lazy" src={img_32} />
                    <img loading="lazy" src={img_33} />
                    <img loading="lazy" src={img_34} />
                    <img loading="lazy" src={img_35} />
                    <img loading="lazy" src={img_36} />
                    <img loading="lazy" src={img_37} />
                    <img loading="lazy" src={img_38} />
                    <img loading="lazy" src={img_39} />
                    <img loading="lazy" src={img_40} />
                </div>                
                <p>On reprend la route à 13h30 et on se dirige maintenant vers une ancienne voie ferrée réhabiliter en vélo-route, c'est la "route du lin" qui va jusqu'à Fécamp. C'est très agréable de rouler dessus car les montés sont longues et douces.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_41} />
                    <img loading="lazy" src={img_42} />
                    <img loading="lazy" src={img_43} />
                    <img loading="lazy" src={img_44} />
                    <img loading="lazy" src={img_45} />
                    <img loading="lazy" src={img_46} />
                    <img loading="lazy" src={img_47} />
                    <img loading="lazy" src={img_48} />
                    <img loading="lazy" src={img_49} />
                    <img loading="lazy" src={img_50} />
                    <img loading="lazy" src={img_51} />
                    <img loading="lazy" src={img_52} />
                    <img loading="lazy" src={img_53} />
                </div>                
                <p>On arrive finalement à Colleville avec 86 km au compteur et on plante la tente entres les étangs.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_54} />
                    <img loading="lazy" src={img_55} />
                    <img loading="lazy" src={img_56} />
                    <img loading="lazy" src={img_57} />
                    <img loading="lazy" src={img_58} />
                    <img loading="lazy" src={img_59} />
                    <img loading="lazy" src={img_60} />
                </div>
                <div className="previous-next-days" >
                    <Link to="/pages/montsaintmichel/j3">Jour précédent</Link>
                    <Link to="/pages/montsaintmichel/j5">Jour suivant</Link>
                </div>

            </div>
        );
    }

}