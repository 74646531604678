import React from "react";
import { Link } from "react-router-dom";

const img_1 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/1.jpg";
const img_2 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/2.jpg";
const img_3 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/3.jpg";
const img_4 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/4.jpg";
const img_5 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/5.jpg";
const img_6 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/6.jpg";
const img_7 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/7.jpg";
const img_8 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/8.jpg";
const img_9 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/9.jpg";
const img_10 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/10.jpg";
const img_11 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/11.jpg";
const img_12 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/12.jpg";
const img_13 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/13.jpg";
const img_14 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/14.jpg";
const img_15 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/15.jpg";
const img_16 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/16.jpg";
const img_17 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/17.jpg";
const img_18 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/18.jpg";
const img_19 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/19.jpg";
const img_20 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/20.jpg";
const img_21 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/21.jpg";
const img_22 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/22.jpg";
const img_23 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/23.jpg";
const img_24 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/24.jpg";
const img_25 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/25.jpg";
const img_26 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/26.jpg";
const img_27 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/27.jpg";
const img_28 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/28.jpg";
const img_29 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/29.jpg";
const img_30 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/30.jpg";
const img_31 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/31.jpg";
const img_32 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/32.jpg";
const img_33 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/33.jpg";
const img_34 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/34.jpg";
const img_35 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/35.jpg";
const img_36 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/36.jpg";
const img_37 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/37.jpg";
const img_38 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/38.jpg";
const img_39 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/39.jpg";
const img_40 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/40.jpg";
const img_41 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/41.jpg";
const img_42 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/42.jpg";
const img_43 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/43.jpg";
const img_44 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/44.jpg";
const img_45 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/45.jpg";
const img_46 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/46.jpg";
const img_47 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/47.jpg";
const img_48 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/48.jpg";
const img_49 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/49.jpg";
const img_50 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/50.jpg";
const img_51 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/51.jpg";
const img_52 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/52.jpg";
const img_53 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/53.jpg";
const img_54 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/54.jpg";
const img_55 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/55.jpg";
const img_56 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/56.jpg";
const img_57 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/57.jpg";
const img_58 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/58.jpg";
const img_59 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/59.jpg";
const img_60 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/60.jpg";
const img_61 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/61.jpg";
const img_62 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/62.jpg";
const img_63 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j3/63.jpg";


export default class Journee3 extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="montsaintmichel-j-page" id="montsaintmichel-j3-page">
                <h1>Samedi - La baie de Somme</h1>
                <h2>La côte</h2>
                <table><tbody><tr><th>Distance (km)</th><th>Temps en route</th><th>Horaires</th></tr><tr><td>94.723</td><td>---</td><td>8h-12h-13h45-19h45</td></tr></tbody></table>
                <p>Pour commencer la journée on part dans le centre d'Abbeville y trouver une boulangerie et on déjeune sur la place de l'église gothique Saint Vulfran, on croise une allemande qui elle aussi fait du vélo, partie du Danemark elle suit la côte dans quelques jours elle rejoindra Paris (son compteur indique 1401 km, pas mal !).</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_1} />
                    <img loading="lazy" src={img_2} />
                </div>
                <p>On cherche à rejoindre la côte donc on suit une vélo route qui s'arrête à Saint-Valery sur Somme.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_3} />
                    <img loading="lazy" src={img_4} />
                    <img loading="lazy" src={img_5} />
                    <img loading="lazy" src={img_6} />
                    <img loading="lazy" src={img_7} />
                    <img loading="lazy" src={img_8} />
                </div>
                <p>Sur le barrage, un vieux train en bois tiré par une locomotive à vapeur passe, et s'arrête au bout du port.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_9} />
                    <img loading="lazy" src={img_10} />
                    <img loading="lazy" src={img_11} />
                    <img loading="lazy" src={img_12} />
                    <img loading="lazy" src={img_13} />
                    <img loading="lazy" src={img_14} />
                    <img loading="lazy" src={img_15} />
                    <img loading="lazy" src={img_16} />
                    <img loading="lazy" src={img_17} />
                    <img loading="lazy" src={img_18} />
                    <img loading="lazy" src={img_19} />
                    <img loading="lazy" src={img_20} />
                    <img loading="lazy" src={img_21} />
                </div>
                <p>Puis on continue jusqu'à la pointe du Hourdel où lon s'arrête pour manger. On sort le réchaud et ce midi c'est riz, macédoine et lentilles.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_22} />
                    <img loading="lazy" src={img_23} />
                    <img loading="lazy" src={img_24} />
                    <img loading="lazy" src={img_25} />
                    <img loading="lazy" src={img_26} />
                    <img loading="lazy" src={img_27} />
                    <img loading="lazy" src={img_28} />
                    <img loading="lazy" src={img_29} />
                    <img loading="lazy" src={img_30} />
                    <img loading="lazy" src={img_31} />

                </div>
                <p>On longe la plage de Cayeux-sur-Mer avcec toutes ces cabanes et enfin on voit nos premières falaises, celles de Ault.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_32} />
                    <img loading="lazy" src={img_33} />
                    <img loading="lazy" src={img_34} />
                    <img loading="lazy" src={img_35} />
                    <img loading="lazy" src={img_36} />
                    <img loading="lazy" src={img_37} />
                    <img loading="lazy" src={img_38} />
                    <img loading="lazy" src={img_39} />
                    <img loading="lazy" src={img_40} />
                    <img loading="lazy" src={img_41} />
                    <img loading="lazy" src={img_42} />
                </div>
                <p>Mers-les-Bains et Le Tréport.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_43} />
                    <img loading="lazy" src={img_44} />
                    <img loading="lazy" src={img_45} />
                    <img loading="lazy" src={img_46} />
                    <img loading="lazy" src={img_47} />
                    <img loading="lazy" src={img_48} />
                    <img loading="lazy" src={img_49} />
                    <img loading="lazy" src={img_50} />
                    <img loading="lazy" src={img_51} />
                    <img loading="lazy" src={img_52} />
                    <img loading="lazy" src={img_53} />
                    <img loading="lazy" src={img_54} />
                </div>
                <p>En haut des falaises, ça grimpe.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_55} />
                    <img loading="lazy" src={img_56} />
                    <img loading="lazy" src={img_57} />
                </div>
                <p>Criel Plage une petite ville balnéaire.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_58} />
                    <img loading="lazy" src={img_59} />
                    <img loading="lazy" src={img_60} />
                    <img loading="lazy" src={img_61} />
                </div>
                <p>Pour finir la journée des gouttes se font sentir enfaite c'est un orage et il va pleuvoir des cordes, on a peu de temps pour rentrer au camping avant de se la prendre. On arrive à Berneval-Le-Grand et on se réfugie sous le préau de la terrasse où on se met à l'aise, lavage du linge, repas bien chaud et recharge des batteries.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_62} />
                    <img loading="lazy" src={img_63} />
                </div>
                <div className="previous-next-days" >
                    <Link to="/pages/montsaintmichel/j2">Jour précédent</Link>
                    <Link to="/pages/montsaintmichel/j4">Jour suivant</Link>
                </div>

            </div>
        );
    }

}