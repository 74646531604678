import React from "react";

import "../../../styles/pages/pages.css";
import "../../../styles/pages/montsaintmichel.css";
import { Link } from "react-router-dom";

const img_1 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j1/1.jpg";
const img_2 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j1/2.jpg";
const img_3 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j1/3.jpg";
const img_4 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j1/4.jpg";
const img_5 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j1/5.jpg";
const img_6 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j1/6.jpg";
const img_7 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j1/7.jpg";
const img_8 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j1/8.jpg";
const img_9 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j1/9.jpg";
const img_10 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j1/10.jpg";
const img_11 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j1/11.jpg";
const img_12 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j1/12.jpg";
const img_13 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j1/13.jpg";

export default class Journee1 extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="montsaintmichel-j-page" id="montsaintmichel-j1-page">
                <h1>Jeudi 2 Juin 2022</h1>
                <h2>Le départ</h2>
                <table><tbody><tr><th>Distance (km)</th><th>Temps en route</th><th>Horaires</th></tr><tr><td>71.979</td><td>---</td><td>15h30-22h30</td></tr></tbody></table>
                <p>L'objectif de la journée est de rattraper le retard pris. Il faut passer Bruay-la-Brussiere et être environ à mi-chemin d'Abeville. Seulement sur la route il y toujours des imprévus et à La Bassé la chambre à air d'Oussama lâche.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_1} />
                    <img loading="lazy" src={img_2} />
                    <img loading="lazy" src={img_3} />
                </div>
                <p>Mais on repart de plus belle et on arrive à Bethune et sa Grand Place.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_4} />
                    <img loading="lazy" src={img_5} />
                    <img loading="lazy" src={img_6} />
                </div>
                <p>On continue de s'enfoncer dans le Pas-de-Calais et ça commence a être vallonné, finalement on cherche un endroit où installer la tente, le camping est fermé mais un habitant bien aimable nous indique le terrain de la mairie et remplie ma bouteille.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_7} />
                    <img loading="lazy" src={img_8} />
                    <img loading="lazy" src={img_9} />
                    <img loading="lazy" src={img_10} />
                    <img loading="lazy" src={img_11} />
                </div>
                <p>On s'y installe dans un coin sous un arbre, il fait déjà noir. Les vélos sont cachés et on se dit bonne nuit. On aura fait les 70kms de la journée. </p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_12} />
                    <img loading="lazy" src={img_13} />
                </div>
                <div className="previous-next-days" >
                    <Link to="/pages/montsaintmichel/j2">Jour suivant</Link>
                </div>
                
            </div>
        );
    }

}
