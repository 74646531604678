import React from "react";

import "../../styles/errorPages.css";

export default class Error404 extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return(
            <div className="error-page" id="error-404">
                <h1>Error 404</h1>
                <h2>Not found</h2>
                The page you are looking for, doesn't exist now. Maybe was deleted or moved on another url.
            </div>
        );
    }

}