import React from "react";
import { Link } from "react-router-dom";

const img_1 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/1.jpg";
const img_2 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/2.jpg";
const img_3 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/3.jpg";
const img_4 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/4.jpg";
const img_5 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/5.jpg";
const img_6 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/6.jpg";
const img_7 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/7.jpg";
const img_8 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/8.jpg";
const img_9 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/9.jpg";
const img_10 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/10.jpg";
const img_11 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/11.jpg";
const img_12 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/12.jpg";
const img_13 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/13.jpg";
const img_14 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/14.jpg";
const img_15 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/15.jpg";
const img_16 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/16.jpg";
const img_17 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/17.jpg";
const img_18 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/18.jpg";
const img_19 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/19.jpg";
const img_20 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/20.jpg";
const img_21 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/21.jpg";
const img_22 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/22.jpg";
const img_23 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/23.jpg";
const img_24 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/24.jpg";
const img_25 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/25.jpg";
const img_26 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/26.jpg";
const img_27 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/27.jpg";
const img_28 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/28.jpg";
const img_29 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j8/29.jpg";


export default class Journee8 extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="montsaintmichel-j-page" id="montsaintmichel-j8-page">
                <h1>Jeudi 9 Juin</h1>
                <h2>Blabla</h2>
                <table><tr><th>Distance (km)</th><th>Temps en route</th><th>Horaires</th></tr><tr><td>80.562</td><td>4h58</td><td>8h45-21h</td></tr></table>
                <p>Nous repartons de Saint-Rémy, il y a une ancienne mine à charbon sur notre route et on quitte maintenant le cours de l'Orne.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_1} />
                    <img loading="lazy" src={img_2} />
                    <img loading="lazy" src={img_3} />
                    <img loading="lazy" src={img_4} />
                    <img loading="lazy" src={img_5} />
                    <img loading="lazy" src={img_6} />
                    <img loading="lazy" src={img_7} />
                    <img loading="lazy" src={img_8} />
                    <img loading="lazy" src={img_9} />
                    <img loading="lazy" src={img_10} />
                    <img loading="lazy" src={img_11} />
                    <img loading="lazy" src={img_12} />
                    <img loading="lazy" src={img_13} />
                    <img loading="lazy" src={img_14} />
                    <img loading="lazy" src={img_15} />
                    <img loading="lazy" src={img_16} />
                    <img loading="lazy" src={img_17} />
                    <img loading="lazy" src={img_18} />
                    <img loading="lazy" src={img_19} />
                    <img loading="lazy" src={img_20} />
                    <img loading="lazy" src={img_21} />
                    <img loading="lazy" src={img_22} />
                    <img loading="lazy" src={img_23} />
                    <img loading="lazy" src={img_24} />
                    <img loading="lazy" src={img_25} />
                    <img loading="lazy" src={img_26} />
                    <img loading="lazy" src={img_27} />
                    <img loading="lazy" src={img_28} />
                    <img loading="lazy" src={img_29} />
                </div>                
                <p>Après deux crevaisons successives et quelques autres péripéties nous voilà enfin arrivé au camping municipal de Saint Hilaire du Harcouet</p>

                <div className="previous-next-days" >
                    <Link to="/pages/montsaintmichel/j7">Jour précédent</Link>
                    <Link to="/pages/montsaintmichel/j9">Jour suivant</Link>
                </div>

            </div>
        );
    }

}