import React from "react";
import { Link } from "react-router-dom";

import "../../styles/layout.css";
import LinkImaged from "./LinkImaged";

const home_ico = "https://datas.blog.mpaty.fr/images/icones/home.png";
const wall_ico = "https://datas.blog.mpaty.fr/images/icones/wall.png";
const contacts_ico = "https://datas.blog.mpaty.fr/images/icones/contacts.png";

export default class Layout extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="layout">
                <nav id="layout-link-nav">
                    <LinkImaged class="layout-link"  linkClass="layout-link-a" linkId="back-home-link" toPath="/home" placeholder="" imageSrc={home_ico} title="page d'accueil" alt="page d'accueil" />
                    <LinkImaged class="layout-link"  linkClass="layout-link-a" linkId="all-pages-link" toPath="/pages" placeholder="" imageSrc={wall_ico} title="tous les projets" alt="tous les projets" />
                    <LinkImaged class="layout-link"  linkClass="layout-link-a" linkId="contacts-link" toPath="/contact" placeholder="" imageSrc={contacts_ico} title="mes coordonnées" alt="mes coordonnées" />
                </nav>
            </div>
        );
    }


}
