import React from "react";
import { Link } from "react-router-dom";

const img_1 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/1.jpg";
const img_2 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/2.jpg";
const img_3 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/3.jpg";
const img_4 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/4.jpg";
const img_5 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/5.jpg";
const img_6 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/6.jpg";
const img_7 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/7.jpg";
const img_8 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/8.jpg";
const img_9 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/9.jpg";
const img_10 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/10.jpg";
const img_11 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/11.jpg";
const img_12 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/12.jpg";
const img_13 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/13.jpg";
const img_14 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/14.jpg";
const img_15 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/15.jpg";
const img_16 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/16.jpg";
const img_17 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/17.jpg";
const img_18 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/18.jpg";
const img_19 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/19.jpg";
const img_20 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/20.jpg";
const img_21 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/21.jpg";
const img_22 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/22.jpg";
const img_23 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j9/23.jpg";


export default class Journee9 extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="montsaintmichel-j-page" id="montsaintmichel-j9-page">
                <h1>Le Mont Saint-Michel</h1>
                <table><tr><th>Distance (km)</th><th>Temps en route</th><th>Horaires</th></tr><tr><td>41.955</td><td>2h27</td><td>13h45-18h</td></tr></table>
                <p>Aujourd'hui c'est la dernière ! Le Mont Saint-Michel n'est plus très loin et on part assez tard du camping.</p>
                <p>Et hop une crevaison !</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_1} />
                </div>                
                <p>On l'aperçois.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_2} />
                    <img loading="lazy" src={img_3} />
                    <img loading="lazy" src={img_4} />
                    <img loading="lazy" src={img_5} />
                    <img loading="lazy" src={img_6} />
                    <img loading="lazy" src={img_7} />
                    <img loading="lazy" src={img_8} />
                    <img loading="lazy" src={img_9} />
                    <img loading="lazy" src={img_10} />
                    <img loading="lazy" src={img_11} />
                    <img loading="lazy" src={img_12} />
                    <img loading="lazy" src={img_13} />
                </div>                
                <p>Le prè salé.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_14} />
                    <img loading="lazy" src={img_15} />
                    <img loading="lazy" src={img_16} />
                    <img loading="lazy" src={img_17} />
                    <img loading="lazy" src={img_18} />
                    <img loading="lazy" src={img_19} />
                </div>                
                <p>On y est presque...</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_20} />
                    <img loading="lazy" src={img_21} />
                    <img loading="lazy" src={img_22} />
                </div>                
                <p>Ca y est ! Nous voilà à destination ! Il est 17h 02, on met les vélos dans la caserne du mont et on rentre dans l'enceinte de la ville.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_23} />
                </div>
                <div className="previous-next-days" >
                    <Link to="/pages/montsaintmichel/j8">Jour précédent</Link>
                </div>
                
            </div>
        );
    }

}