import React from "react";
import { Link } from "react-router-dom";

import "../../../../../styles/pages/licenceInfo.css";

const l3s5_coo = "https://datas.blog.mpaty.fr/projets/tps/l3s5_coo.zip"
const l3s5_pds_perf = "https://datas.blog.mpaty.fr/projets/tps/l3s5-pds-tp-perf-main.zip"
const l3s5_pds_mdu = "https://datas.blog.mpaty.fr/projets/tps/l3s5-pds-tp-mdu-main.zip"
const l3s5_pds_tauxgc = "https://datas.blog.mpaty.fr/projets/tps/l3s5-pds-tp-tauxgc-main.zip"
const l3s5_pds_barr = "https://datas.blog.mpaty.fr/projets/tps/l3s5-pds-tp-barriere-main.zip"
const l3s5_pds_entr = "https://datas.blog.mpaty.fr/projets/tps/l3s5-pds-tp-entrenaiment-main.zip"
const l3s5_pf = "https://datas.blog.mpaty.fr/projets/tps/pf_2022-main.zip"
const l3s5_rsx2 = "https://datas.blog.mpaty.fr/projets/tps/rsx1_2022-master-tp2.zip"
const l3s5_rsx3 = "https://datas.blog.mpaty.fr/projets/tps/rsx1_2022-master-tp3.zip"

export default class Licence3 extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="licence-info" id="licence-info-l3">
                <div id="licence-info-header">
                    <div className="licence-info-retour-btn"><Link to="/pages/etudes/licence">{"<"}</Link></div>
                    <h1>Licence Informatique</h1>
                    <h2>Université de Lille</h2>
                    <h2>Année 3 {"(2022-2023)"}</h2>
                </div>
                <br/>
                <div id="left-side">
                    <h2>Composantes</h2>
                    <h3>semestre 5</h3>
                    <ul>
                        <li><a href="#l3s5-coo">Conception Orientée Objet</a></li>
                        <li><a href="#l3s5-pds">Programmation des systèmes</a></li>
                        <li><a href="#l3s5-pf">Programmation Fonctionnelle</a></li>
                        <li><a href="#l3s5-rsx1">Réseaux 1</a></li>
                        <li><a href="#l3s5-ml">Modèles Linéaires</a></li>
                        <li><a href="#l3s5-gr">Graphes</a></li>
                        <li><a href="#l3s5-ihm">Interaction Homme-Machine</a></li>
                    </ul>
                    <h3>semestre 6</h3>
                    <ul>
                        <li><a href="#l3s6-jsfs">Javascript Fullstack</a></li>
                        <li><a href="#l3s6-laas">Langages algébriques et analyse syntaxique</a></li>
                        <li><a href="#l3s6-rsx2">Réseaux 2</a></li>
                        <li><a href="#l3s6-archi">Architecture des ordinateurs</a></li>
                        <li><a href="#l3s6-pds2">Programmation des systèmes 2</a></li>
                        <li><a href="#l3s6-ichp">Introduction au Calcul Haute Performance</a></li>
                    </ul>
                </div>
                <div id="main-container">
                    <h2>Semestre 5</h2>
                    <h3 id="l3s5-coo">Conception Orientée Objet</h3>
                    <p>Projet en binôme. <a href={l3s5_coo}>ici</a></p>
                    
                    <h3 id="l3s5-pds">Programmation des systèmes</h3>
                    <p>Le système de fichiers & les threads.</p>
                    <ol>
                        <li><a href={l3s5_pds_perf}>l3s5-pds-tp-perf-main</a></li>
                        <li><a href={l3s5_pds_mdu}>l3s5-pds-tp-mdu-main</a></li>
                        <li><a href={l3s5_pds_tauxgc}>l3s5-pds-tp-tauxgc-main</a></li>
                        <li><a href={l3s5_pds_barr}>l3s5-pds-tp-barrière-main</a></li>
                        <li><a href={l3s5_pds_entr}>l3s5-pds-tp-examen-blanc-main</a></li>
                    </ol>
                    
                    <h3 id="l3s5-pf">Programmation Fonctionnelle</h3>
                    <p>Avec le langage fonctionnel HASKELL : <a href="http://www.lyah.haskell.fr/introduction">site d'apprentissage.</a></p>
                    <p><a href={l3s5_pf}>Les tps.</a></p>
                    
                    <h3 id="l3s5-rsx1">Réseaux 1</h3>
                    <p>Modèles OSI. Protocoles de transport TCP et UDP. Routage, Hub, Switch, Calcul de débits</p>
                    <p>Architecture réseaux : Topologie, connexions, signaux.</p>
                    <p>Modèle OSI : trames, paquets, encapsulation, protocoles {"(ARP, ICMP, UDP, TCP, FTP)"}.</p>
                    <p>Routage : Adressage, Hub, Switch, Routeur</p>
                    <p>Lors de 2 tps nous avons fait une application de messagerie en <a href={l3s5_rsx2}>UDP</a> et en <a href={l3s5_rsx3}>TCP</a>.</p>
                    
                    <h3 id="l3s5-ml">Modèles Linéaires</h3>
                    <p>Résoudre des problèmes d'optimisation {"(maximisation et minimisation)"} graphiquement et avec AMPL. Savoir modéliser une situation.</p>
                    
                    <h3 id="l3s5-gr">Graphes</h3>
                    <p>La théorie des graphes.</p>

                    <h3 id="l3s5-ihm">Interaction Homme-Machine</h3>
                    <p>Concevoir un système interactif répondant aux besoins des utilisateurs.</p>


                    <h2>Semestre 6</h2>
                    <h3 id="l3s6-jsfs">Javascript Fullsatck</h3>
                    <p>NodeJS et serveur Backend.</p>

                    <h3 id="l3s6-laas">Langages algébriques et Analyse syntaxique</h3>

                    <h3 id="l3s6-rsx2">Réseaux 2</h3>
                    
                    <h3 id="l3s6-archi">Architecture des ordinateurs</h3>

                    <h3 id="l3s6-pds2">Programmation des systèmes 2</h3>
                    <p>Processus lourds.</p>
                    
                    <h3 id="l3s6-ichp">Introduction au Calcul Haute Performance</h3>

                </div>
            </div>
        );
    }

}