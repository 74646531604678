import React from "react";
import { Link } from "react-router-dom";

import "../../styles/contact.css";

const gitlab_icon = "https://datas.blog.mpaty.fr/images/icones/gitlab_icon.svg";
const github_icon = "https://datas.blog.mpaty.fr/images/icones/github_icon.svg";
const itchio_icon = "https://datas.blog.mpaty.fr/images/icones/itchio_icon.svg";

export default class Contact extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div id="contact">
                <h1>Contact</h1>
                <div id="top-contact-line"></div>
                <div id="luciole-left-contact-line"></div>
                <div id="left-contact-line"></div>
                <div className="contact-main-container">
                    <h3>Liens</h3>
                    <div className="my-contact-info"><a href="https://gitlab.com/matthias.p"><img src={gitlab_icon} />Gitlab</a></div>
                    <div className="my-contact-info"><a href="https://github.com/matthiaspaty"><img src={github_icon} />Github</a></div>
                    <div className="my-contact-info"><a href="https://blackhole59.itch.io/"><img src={itchio_icon} />Itch.io</a></div>

                </div>
            </div>
        );
    }

}

  