import React from "react";
import { Link } from "react-router-dom";

import "../../../../../styles/pages/licenceInfo.css";

const l1s2_prj_films_favs = "https://datas.blog.mpaty.fr/projets/l1s2_tw1_films_favoris/index.html";
const l1s2_prj_films_favs_pckg = "https://datas.blog.mpaty.fr/projets/l1s2_tw1_films_favoris/l1s2_tw1_films_favoris.rar";
const l1s2_ci_exos_2 = "https://datas.blog.mpaty.fr/documents/l1s2_codage_td2.pdf";
const l1s2_ci_exos_3 = "https://datas.blog.mpaty.fr/documents/l1s2_codage_td3.pdf";

export default class Licence1 extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="licence-info" id="licence-info-l1">
                <div id="licence-info-header">
                    <div className="licence-info-retour-btn"><Link to="/pages/etudes/licence">{"<"}</Link></div>
                    <h1>Licence Informatique</h1>
                    <h2>Université de Lille</h2>
                    <h2>Année 1 {"(2020-2021)"}</h2>
                </div>
                <br/>
                <div id="left-side">
                    <h2>Composantes</h2>
                    <ul>
                        <li><a href="#l1s2-tech-web-1">Technologie du web 1</a></li>
                        <li><a href="#l1s2-algo-prgm">Algorithme & programmation</a></li>
                        <li><a href="#l1s2-codage-info">Codage de l'information</a></li>
                    </ul>
                </div>
                <div id="main-container">
                    <h3 id="l1s2-tech-web-1">Technologie du web 1</h3>
                    <p>Premiers pas sur HTML avec CSS et Javascript</p>
                    <p>Projet de fin d'UE : <a href={l1s2_prj_films_favs}>Vos films favoris !</a></p>
                    <p>Archive .RAR <a href={l1s2_prj_films_favs_pckg}>ici</a></p>
                    <h3 id="l1s2-algo-prgm">Algorithme et programmation</h3>
                    <p>algorithme de gestion d'information, jeu de bataille aux cartes, tries, recursivité, en python</p>
                    <h3 id="l1s2-codage-info">Codage de l'information</h3>
                    <p>Représentation des nombres dans d'autres bases, notamment : binaire, octale, héxadécimale. <a href={l1s2_ci_exos_2}>{"(exos)"}</a></p>
                    <p>Manipulation et opération sur des entiers, signés ou non-signés. <a href={l1s2_ci_exos_3}>{"(exos)"}</a></p>                
                </div>
            </div>
        );
    }

}