import React from "react";
import { Link } from "react-router-dom";

const img_1 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/1.jpg";
const img_2 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/2.jpg";
const img_3 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/3.jpg";
const img_4 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/4.jpg";
const img_5 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/5.jpg";
const img_6 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/6.jpg";
const img_7 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/7.jpg";
const img_8 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/8.jpg";
const img_9 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/9.jpg";
const img_10 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/10.jpg";
const img_11 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/11.jpg";
const img_12 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/12.jpg";
const img_13 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/13.jpg";
const img_14 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/14.jpg";
const img_15 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/15.jpg";
const img_16 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/16.jpg";
const img_17 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/17.jpg";
const img_18 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/18.jpg";
const img_19 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/19.jpg";
const img_20 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/20.jpg";
const img_21 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/21.jpg";
const img_22 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/22.jpg";
const img_23 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/23.jpg";
const img_24 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/24.jpg";
const img_25 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/25.jpg";
const img_26 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/26.jpg";
const img_27 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/27.jpg";
const img_28 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/28.jpg";
const img_29 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/29.jpg";
const img_30 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/30.jpg";
const img_31 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/31.jpg";
const img_32 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/32.jpg";
const img_33 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/33.jpg";
const img_34 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/34.jpg";
const img_35 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/35.jpg";
const img_36 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/36.jpg";
const img_37 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/37.jpg";
const img_38 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/38.jpg";
const img_39 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/39.jpg";
const img_40 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/40.jpg";
const img_41 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/41.jpg";
const img_42 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/42.jpg";
const img_43 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/43.jpg";
const img_44 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/44.jpg";
const img_45 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/45.jpg";
const img_46 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/46.jpg";
const img_47 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/47.jpg";
const img_48 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/48.jpg";
const img_49 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/49.jpg";
const img_50 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/50.jpg";
const img_51 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/51.jpg";
const img_52 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/52.jpg";
const img_53 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j7/53.jpg";


export default class Journee7 extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="montsaintmichel-j-page" id="montsaintmichel-j7-page">
                <h1>Mercredi - Caen</h1>
                <h2>Le long de l'Orne</h2>
                <table><tr><th>Distance (km)</th><th>Temps en route</th><th>Horaires</th></tr><tr><td>78.127</td><td>4h42</td><td>11h-20h</td></tr></table>
                <p>Cette journée commence, comme hier, avec un ciel couvert, le temps est humide et parfois il y a des gouttelettes. On avance doucement vers Caen en passant par des jolies villes.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_1} />
                    <img loading="lazy" src={img_2} />
                    <img loading="lazy" src={img_3} />
                    <img loading="lazy" src={img_4} />
                    <img loading="lazy" src={img_5} />
                    <img loading="lazy" src={img_6} />
                    <img loading="lazy" src={img_7} />
                    <img loading="lazy" src={img_8} />
                </div>                
                <p>Le pont Horsa (Pegasus Bridge) qui traverse l'Orne est un pont atypique depart son fonctionnement et a été un point stratégique a prendre pour les Alliés durant le jour J.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_9} />
                    <img loading="lazy" src={img_10} />
                    <img loading="lazy" src={img_11} />
                    <img loading="lazy" src={img_12} />
                    <img loading="lazy" src={img_13} />
                    <img loading="lazy" src={img_14} />
                </div>                
                <p>D'ici Ouistréham est au nord mais nous on va remonter l'Orne vers Caen, puis continuer plus loin en empruntant une véloroute qui longe le fleuve.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_15} />
                    <img loading="lazy" src={img_16} />
                    <img loading="lazy" src={img_17} />
                    <img loading="lazy" src={img_18} />
                </div>                
                <p>Caen :</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_19} />
                    <img loading="lazy" src={img_20} />
                </div>                
                <p>Les bords de l'Orne sont très plaisants.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_21} />
                    <img loading="lazy" src={img_22} />
                    <img loading="lazy" src={img_23} />
                    <img loading="lazy" src={img_24} />
                    <img loading="lazy" src={img_25} />
                    <img loading="lazy" src={img_26} />
                    <img loading="lazy" src={img_27} />
                    <img loading="lazy" src={img_28} />
                    <img loading="lazy" src={img_29} />
                    <img loading="lazy" src={img_30} />
                    <img loading="lazy" src={img_31} />
                    <img loading="lazy" src={img_32} />
                    <img loading="lazy" src={img_33} />
                    <img loading="lazy" src={img_34} />
                    <img loading="lazy" src={img_35} />
                    <img loading="lazy" src={img_36} />
                    <img loading="lazy" src={img_37} />
                    <img loading="lazy" src={img_38} />
                    <img loading="lazy" src={img_39} />
                    <img loading="lazy" src={img_40} />
                </div>                
                <p>La route c'est fini pour aujourd'hui car on a trouvé le bivouac idéal !</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_41} />
                    <img loading="lazy" src={img_42} />
                    <img loading="lazy" src={img_43} />
                    <img loading="lazy" src={img_44} />
                    <img loading="lazy" src={img_45} />
                    <img loading="lazy" src={img_46} />
                </div>                
                <p>La faim se fait sentir et on sort le matos de cuisine. On installe le réchaud dans une petite cabane délabrée parce qu'il y a 30 boulettes de viandes qui attendent d'être cuite ! On lance une première cuisson dans la casserole de 6 boulettes, seulement ce que l'on craint arrive et le réchaud s'éteint, plus de gaz. Ces 24 boulettes restantes font pitiés on a une autre solution...</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_47} />
                    <img loading="lazy" src={img_48} />
                    <img loading="lazy" src={img_49} />
                    <img loading="lazy" src={img_50} />
                    <img loading="lazy" src={img_51} />
                    <img loading="lazy" src={img_52} />
                    <img loading="lazy" src={img_53} />
                </div>                
                <p>Avec des pierres et des branches trouvées autour on s'est fait une petite cuisine. Le tout c'est de ne pas s'embéter avec l'allumage et d'y mettre directement de l'alcool à désinfecter, ça prend plus facilement.</p>
                <p>La journée se termine au calme et avec une bonne odeur de feu de camp sur les vêtements ahah !</p>
                <div className="previous-next-days" >
                    <Link to="/pages/montsaintmichel/j6">Jour précédent</Link>
                    <Link to="/pages/montsaintmichel/j8">Jour suivant</Link>
                </div>

            </div>
        );
    }

}