import React from "react";
import { Link } from "react-router-dom";

const img_1 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/1.jpg";
const img_2 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/2.jpg";
const img_3 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/3.jpg";
const img_4 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/4.jpg";
const img_5 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/5.jpg";
const img_6 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/6.jpg";
const img_7 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/7.jpg";
const img_8 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/8.jpg";
const img_9 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/9.jpg";
const img_10 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/10.jpg";
const img_11 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/11.jpg";
const img_12 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/12.jpg";
const img_13 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/13.jpg";
const img_14 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/14.jpg";
const img_15 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/15.jpg";
const img_16 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/16.jpg";
const img_17 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/17.jpg";
const img_18 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/18.jpg";
const img_19 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/19.jpg";
const img_20 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/20.jpg";
const img_21 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/21.jpg";
const img_22 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/22.jpg";
const img_23 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/23.jpg";
const img_24 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/24.jpg";
const img_25 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j2/25.jpg";

export default class Journee2 extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="montsaintmichel-j-page" id="montsaintmichel-j2-page">
                <h1>Vendredi - Abbeville</h1>
                <h2>La traversée du Pas-De-Calais</h2>
                <table><tbody><tr><th>Distance (km)</th><th>Temps en route</th><th>Horaires</th></tr><tr><td>79.776</td><td>---</td><td>10h30-12h45-15h-20h45</td></tr></tbody></table>
                <p>Le réveil se fait doucement il y a du soleil.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_1} />
                </div>
                <p>Pour le petit déjeuner on a prévu une omelette avec des champignons mais d'abord il faut faire la toilette et l'eau du cimetière de Villers-Brûlin fera bien l'affaire.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_2} />
                    <img loading="lazy" src={img_3} />
                    <img loading="lazy" src={img_4} />
                    <img loading="lazy" src={img_5} />
                </div>
                <p>La traversée au Pas-de-Calais se fait sous le soleil, on passe dans des petits villages toujours centrés autour de l'église.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_6} />
                    <img loading="lazy" src={img_7} />
                    <img loading="lazy" src={img_8} />
                    <img loading="lazy" src={img_9} />
                    <img loading="lazy" src={img_10} />
                    <img loading="lazy" src={img_11} />
                </div>
                <p>À midi on arrive à Boubers-sur-canche. Et quelle aubaine car sur la place il y a un restaurant.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_12} />
                    <img loading="lazy" src={img_13} />
                    <img loading="lazy" src={img_14} />
                    <img loading="lazy" src={img_15} />
                    <img loading="lazy" src={img_16} />
                </div>
                <p>Le "plat pays" est déjà loin car c'est déjà vallonné et on arrive dans la Somme.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_17} />
                    <img loading="lazy" src={img_18} />
                    <img loading="lazy" src={img_19} />
                </div>
                <p>Voici Saint-Riquier et son Abbaye Royale.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_20} />
                    <img loading="lazy" src={img_21} />
                    <img loading="lazy" src={img_22} />
                    <img loading="lazy" src={img_23} />
                </div>
                <p>Enfin nous arrivons à Abbeville, il nous faut trouver le repas du soir et le camping.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_24} />
                </div>
                <p>Pour cette nuit ça sera le camping municipal de Mareuil-Caubert près des étangs (voilà les moustiques !).</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_25} />
                </div>
                <div className="previous-next-days" >
                    <Link to="/pages/montsaintmichel/j1">Jour précédent</Link>
                    <Link to="/pages/montsaintmichel/j3">Jour suivant</Link>
                </div>

            </div>
        );
    }

}