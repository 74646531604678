import React from "react";
import { Link } from "react-router-dom";

import "../../../../styles/pages/licenceInfo.css";

export default class LicenceInfo extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="licence-info" id="licence-info-home">
                <div id="licence-info-header">
                    <h1>Licence Informatique</h1>
                    <h2>Université de Lille</h2>
                </div>
                <br/>
                <div id="left-side">
                    <h2>Par années</h2>
                    <ol>
                        <li><Link to="/pages/etudes/licence/l1">ère année</Link></li>
                        <li><Link to="/pages/etudes/licence/l2">ème année</Link></li>
                        <li><Link to="/pages/etudes/licence/l3">ème année</Link></li>
                    </ol>
                </div>
                <div id="main-container">
                    <h3>Licence info</h3>
                    <h2>Licence 1 - 2020-21</h2>
                    <p>La L1 SESI, pour "Science Exactes et sciences pour l'ingénieur", est une année générale, avec un semestre s1 commun : maths, physique, mécanique, électronique, et un semestre s2 avec des options.</p>
                    <p>Nous débutons la programmation avec Python.</p>
                    <Link to="/pages/etudes/licence/l1">voir page.</Link>
                    <h2>Licence 2 - 2021-22</h2>
                    <p>Introduction au sys. UNIX</p>
                    <p>Enseignement de la POO, des bases de données et des technologies du web.</p>
                    <Link to="/pages/etudes/licence/l2">voir page.</Link>
                    <h2>Licence 3 - 2022-23</h2>
                    <p>Approfondissement des notions de L2</p>
                    <p>Orientation vers le Master.</p>
                    <Link to="/pages/etudes/licence/l3">voir page.</Link>
                    <p>Stage de fin d'études.</p>
                </div>
            </div>
        );
    }

}