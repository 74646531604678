import React from "react";
import { Link } from "react-router-dom";

import "../../styles/home.css";

const profil_image = "https://datas.blog.mpaty.fr/images/montsaintmichel_ex_1.jpg";

export default class Home extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div id="home">
                <h1>Accueil</h1>
                <div id="top-home-line"></div>
                <div id="luciole-left-home-line"></div>
                <div id="left-home-line"></div>
                <div className="home-main-container">
                    <div className="home-top-header-profil">
                        <div className="left-profil-info">
                            <p>Etudiant en Informatique.</p>
                            <h2 className="see-my-projects-link inverted-chroma-text"><Link to="/pages">Voir tous mes projets.</Link></h2>
                        </div>
                        <img src={profil_image} alt="Ault en juin 2022" title="Ault en juin 2022" />
                    </div>

                    
                    
                    <h2>Etudes</h2>
                    <h3><Link to="/pages/etudes/licence">Licence Informatique {"(Université de Lille)"}</Link></h3>
                    <ol>
                        <li><u><Link to="/pages/etudes/licence/l1">L1 année SESI</Link></u></li>
                        <li><u><Link to="/pages/etudes/licence/l2">L2 semestre 3</Link></u></li>
                        <ul>
                            <li>Automates, langages régulier ou "<strong>regex</strong>"</li>
                            <li>Programmation orientée Objet</li>
                            <li>Logique: syntaxe et sémantique</li>
                            <li><strong>UNIX</strong> : Arcitecture des ordinateurs</li>
                            <li>Bases de données : <strong>SQL</strong> et <strong>postgres</strong></li>
                            <li>Technologie du web : <strong>PHP</strong></li>
                        </ul>
                        <li><u><Link to="/pages/etudes/licence/l2">L2 semestre 4</Link></u></li>
                        <ul>
                            <li>Algorithme et structure de données</li>
                            <li>Compression de l'information, détection et correction d'erreurs</li>
                            <li>Programmation en <strong>langage C</strong></li>
                            <li>Projet logiciel <strong>JAVA</strong> : <Link to="/pages/labyrinthepoo2022">jeu du labyrinthe</Link></li>
                            <li>Bases de données : conception</li>
                            <li>Javascript : <strong>ReactJS</strong></li>
                        </ul>
                        <li><u><Link to="/pages/etudes/licence/l3">L3 semestre 5</Link></u></li>
                        <ul>
                            <li>Démarche scientifique</li>
                            <li>Modèles linéaires : problèmes de maximisation/min.</li>
                            <li>Graphes : parcours, plus court chemin, etc...</li>
                            <li>Conception Orientée Objet</li>
                            <li>Programmation Fonctionnelle : <strong>Haskell</strong></li>
                            <li>Interaction Homme-Machine</li>
                            <li>Programmation des systèmes : sys. de fichiers & processus légers {"("}<strong>threads</strong>{")"}</li>
                            <li><strong>Réseaux</strong> : OSI, paquets UDP, TCP, routage, hub, switch, etc...</li>
                        </ul>
                        <li><u><Link to="/pages/etudes/licence/l3">L3 semestre 6</Link></u></li>
                        <ul>
                            <li>Javascript FullStack : <strong>NodeJS</strong> - Backend</li>
                            <li>Langages algébriques et analyse syntaxique {"(regex suite)"}</li>
                            <li>Réseaux 2</li>
                            <li>Architecture des ordinateurs</li>
                            <li>Programmation des systèmes : processus lourds</li>
                            <li>Opt. découverte : Introduction au <strong>calcul</strong> haute performance</li>
                        </ul>
                    </ol>

                </div>
            </div>
        );
    }

}

  