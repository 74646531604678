import React from "react";
import { Outlet, Link, useParams } from "react-router-dom";
import Layout from "./interface/Layout";

class Root extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <>
                <Layout />
                <Outlet />
            </>
        );
    }
}

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
  }
  
export default withParams(Root);