import React from "react";
import { Link } from "react-router-dom";

const img_1 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/1.jpg";
const img_2 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/2.jpg";
const img_3 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/3.jpg";
const img_4 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/4.jpg";
const img_5 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/5.jpg";
const img_6 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/6.jpg";
const img_7 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/7.jpg";
const img_8 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/8.jpg";
const img_9 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/9.jpg";
const img_10 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/10.jpg";
const img_11 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/11.jpg";
const img_12 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/12.jpg";
const img_13 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/13.jpg";
const img_14 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/14.jpg";
const img_15 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/15.jpg";
const img_16 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/16.jpg";
const img_17 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/17.jpg";
const img_18 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/18.jpg";
const img_19 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/19.jpg";
const img_20 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/20.jpg";
const img_21 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/21.jpg";
const img_22 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/22.jpg";
const img_23 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/23.jpg";
const img_24 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/24.jpg";
const img_25 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/25.jpg";
const img_26 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/26.jpg";
const img_27 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/27.jpg";
const img_28 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/28.jpg";
const img_29 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/29.jpg";
const img_30 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/30.jpg";
const img_31 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/31.jpg";
const img_32 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/32.jpg";
const img_33 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/33.jpg";
const img_34 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/34.jpg";
const img_35 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/35.jpg";
const img_36 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/36.jpg";
const img_37 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/37.jpg";
const img_38 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/38.jpg";
const img_39 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/39.jpg";
const img_40 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/40.jpg";
const img_41 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/41.jpg";
const img_42 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/42.jpg";
const img_43 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/43.jpg";
const img_44 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/44.jpg";
const img_45 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/45.jpg";
const img_46 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/46.jpg";
const img_47 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/47.jpg";
const img_48 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/48.jpg";
const img_49 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/49.jpg";
const img_50 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/50.jpg";
const img_51 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/51.jpg";
const img_52 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/52.jpg";
const img_53 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/53.jpg";
const img_54 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/54.jpg";
const img_55 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/55.jpg";
const img_56 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/56.jpg";
const img_57 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/57.jpg";
const img_58 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/58.jpg";
const img_59 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/59.jpg";
const img_60 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/60.jpg";
const img_61 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/61.jpg";
const img_62 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/62.jpg";
const img_63 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/63.jpg";
const img_64 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/64.jpg";
const img_65 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/65.jpg";
const img_66 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/66.jpg";
const img_67 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/67.jpg";
const img_68 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/68.jpg";
const img_69 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/69.jpg";
const img_70 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/70.jpg";
const img_71 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/71.jpg";
const img_72 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/72.jpg";
const img_73 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/73.jpg";
const img_74 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/74.jpg";
const img_75 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/75.jpg";
const img_76 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/76.jpg";
const img_77 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/77.jpg";
const img_78 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/78.jpg";
const img_79 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/79.jpg";
const img_80 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/80.jpg";
const img_81 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/81.jpg";
const img_82 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/82.jpg";
const img_83 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/83.jpg";
const img_84 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/84.jpg";
const img_85 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/85.jpg";
const img_86 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/86.jpg";
const img_87 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/87.jpg";
const img_88 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/88.jpg";
const img_89 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/89.jpg";
const img_90 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/90.jpg";
const img_91 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/91.jpg";
const img_92 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/92.jpg";
const img_93 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/93.jpg";
const img_94 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/94.jpg";
const img_95 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/95.jpg";
const img_96 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/96.jpg";
const img_97 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/97.jpg";
const img_98 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/98.jpg";
const img_99 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/99.jpg";
const img_100 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/100.jpg";
const img_101 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/101.jpg";
const img_102 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/102.jpg";
const img_103 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/103.jpg";
const img_104 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/104.jpg";
const img_105 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j6/105.jpg";


export default class Journee6 extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="montsaintmichel-j-page" id="montsaintmichel-j6-page">
                <h1>Mardi - Le pont de Normandie </h1>
                <h2>L'embouchure de la Seine</h2>
                <table><tr><th>Distance (km)</th><th>Temps en route</th><th>Horaires</th></tr><tr><td>77.964</td><td>4h27</td><td>11h-20h15</td></tr></table>
                <p>On se lève doucement et on part pour Le Havre, le ciel est gris et quelques gouttes tombe mais ça se maintient. L'itinéraire que l'on suit c'est L'Eurovélo 4 (EV4) qui est marqué par des petit panneaux à chaque intersection.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_1} />
                    <img loading="lazy" src={img_2} />
                    <img loading="lazy" src={img_3} />
                    <img loading="lazy" src={img_4} />
                </div>
                <p>Voici Le Havre ! On se rejoint au fort car j'avais suivi l'EV4 mais Oussama avait emprunté une autre route, puis on descend vers la plage.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_5} />
                    <img loading="lazy" src={img_6} />
                    <img loading="lazy" src={img_7} />
                    <img loading="lazy" src={img_8} />
                    <img loading="lazy" src={img_9} />
                    <img loading="lazy" src={img_10} />
                    <img loading="lazy" src={img_11} />
                    <img loading="lazy" src={img_12} />
                    <img loading="lazy" src={img_13} />
                    <img loading="lazy" src={img_14} />
                </div>
                <p>On visite la ville, l'église est contemporaine avec ses grands et larges pilliers, son ambiance intérieur et le jeu de lumières même si l'extérieur ne paye pas de mine.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_15} />
                    <img loading="lazy" src={img_16} />
                    <img loading="lazy" src={img_17} />
                    <img loading="lazy" src={img_18} />
                    <img loading="lazy" src={img_19} />
                    <img loading="lazy" src={img_20} />
                    <img loading="lazy" src={img_21} />
                    <img loading="lazy" src={img_22} />
                    <img loading="lazy" src={img_23} />
                    <img loading="lazy" src={img_24} />
                </div>
                <p>Pour rejoindre le pont de Normandie, on  passe par le port, le plus grand de France. C'est la porte sur le monde on y voit des containers partout, des camions qui entrent et qui sortent, des usines et des navires de tout pays.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_25} />
                    <img loading="lazy" src={img_26} />
                    <img loading="lazy" src={img_27} />
                    <img loading="lazy" src={img_28} />
                    <img loading="lazy" src={img_29} />
                    <img loading="lazy" src={img_30} />
                    <img loading="lazy" src={img_31} />
                    <img loading="lazy" src={img_32} />
                    <img loading="lazy" src={img_33} />
                    <img loading="lazy" src={img_34} />
                    <img loading="lazy" src={img_35} />
                    <img loading="lazy" src={img_36} />
                    <img loading="lazy" src={img_37} />
                    <img loading="lazy" src={img_38} />
                    <img loading="lazy" src={img_39} />
                    <img loading="lazy" src={img_40} />
                    <img loading="lazy" src={img_41} />
                    <img loading="lazy" src={img_42} />
                    <img loading="lazy" src={img_43} />
                    <img loading="lazy" src={img_44} />
                    <img loading="lazy" src={img_45} />
                    <img loading="lazy" src={img_46} />
                    <img loading="lazy" src={img_47} />
                    <img loading="lazy" src={img_48} />
                    <img loading="lazy" src={img_49} />
                </div>
                <p>Enfin le voici, le pont de Normandie !</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_50} />
                    <img loading="lazy" src={img_51} />
                    <img loading="lazy" src={img_52} />
                    <img loading="lazy" src={img_53} />
                    <img loading="lazy" src={img_54} />
                    <img loading="lazy" src={img_55} />
                    <img loading="lazy" src={img_56} />
                    <img loading="lazy" src={img_57} />
                    <img loading="lazy" src={img_58} />
                    <img loading="lazy" src={img_59} />
                    <img loading="lazy" src={img_60} />
                    <img loading="lazy" src={img_61} />
                    <img loading="lazy" src={img_62} />
                    <img loading="lazy" src={img_63} />
                    <img loading="lazy" src={img_64} />
                    <img loading="lazy" src={img_65} />
                    <img loading="lazy" src={img_66} />
                    <img loading="lazy" src={img_67} />
                    <img loading="lazy" src={img_68} />
                </div>
                <p>De l'autre côté de la Seine se trouve Marques puis Honfleur et Trouville-sur-mer, Deauville, etc... jusqu'à Blonville-sur-mer où se trouve notre camping.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_69} />
                    <img loading="lazy" src={img_70} />
                    <img loading="lazy" src={img_71} />
                    <img loading="lazy" src={img_72} />
                    <img loading="lazy" src={img_73} />
                    <img loading="lazy" src={img_74} />
                    <img loading="lazy" src={img_75} />
                    <img loading="lazy" src={img_76} />
                    <img loading="lazy" src={img_77} />
                    <img loading="lazy" src={img_78} />
                    <img loading="lazy" src={img_79} />
                    <img loading="lazy" src={img_80} />
                    <img loading="lazy" src={img_81} />
                    <img loading="lazy" src={img_82} />
                    <img loading="lazy" src={img_83} />
                    <img loading="lazy" src={img_84} />
                    <img loading="lazy" src={img_85} />
                    <img loading="lazy" src={img_86} />
                    <img loading="lazy" src={img_87} />
                    <img loading="lazy" src={img_88} />
                    <img loading="lazy" src={img_89} />
                    <img loading="lazy" src={img_90} />
                    <img loading="lazy" src={img_91} />
                    <img loading="lazy" src={img_92} />
                    <img loading="lazy" src={img_93} />
                    <img loading="lazy" src={img_94} />
                    <img loading="lazy" src={img_95} />
                    <img loading="lazy" src={img_96} />
                    <img loading="lazy" src={img_97} />
                    <img loading="lazy" src={img_98} />
                    <img loading="lazy" src={img_99} />
                    <img loading="lazy" src={img_100} />
                    <img loading="lazy" src={img_101} />
                    <img loading="lazy" src={img_102} />
                    <img loading="lazy" src={img_103} />
                    <img loading="lazy" src={img_104} />
                    <img loading="lazy" src={img_105} />
                </div>
                <p>Au camping l'accueil est fermé, on demande alors au gardien s'il a des emplacements disponibles, un sacré personnage, Eric le gardien bientôt en retraite, t-shirt de Top gun et cigarette à la bouche se fiche de nous vendre ou pas la nuit, mais le courant passe bien, on fait la course jusqu'à notre emplacement et il gagne avec son caddie de golf mais s'était serré.</p>

                <div className="previous-next-days" >
                    <Link to="/pages/montsaintmichel/j5">Jour précédent</Link>
                    <Link to="/pages/montsaintmichel/j7">Jour suivant</Link>
                </div>

            </div>
        );
    }

}