import React from "react";
import { Link } from "react-router-dom";

const img_1 = "https://datas.blog.mpaty.fr/images/montsaintmichel/presentation/1.png";
const img_2 = "https://datas.blog.mpaty.fr/images/montsaintmichel/presentation/2.jpg";
const img_3 = "https://datas.blog.mpaty.fr/images/montsaintmichel/presentation/3.jpg";
const img_4 = "https://datas.blog.mpaty.fr/images/montsaintmichel/presentation/4.jpg";

export default class Presentation extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className="montsaintmichel-j-page" id="montsaintmichel-presentation-page">
                <h1>Vélo-rando "Mont Saint-Michel" </h1>
                <h2>Le voyage</h2>
                <p>Destination le mont Saint-Michel sur une dizaine de jours (jeudi 2 juin 2022 à dimanche 12) avec Oussama</p>
                <h2>L'itinéraire</h2>
                <p>Au départ de Lille en passant par Abbeville, Le Havre, Caen.</p>
                <img loading="lazy" src={img_1} />
                <h2>Préparation</h2>
                <p>Pour le voyage il faut bien se préparer et penser à toutes les éventualités. Le couchage dans la tente, des vêtements de pluies. Il faut un réchaud au gaz pour chauffer la nourriture sur la route. Et évidemment en cas de pépins (crevaison ou chute) des outils pour le vélo et des soins pour le cycliste. </p>
                <img loading="lazy" src={img_2} />
                <h2>Départ</h2>
                <p>15h30 - Lomme</p>
                <img loading="lazy" src={img_3} />
                <img loading="lazy" src={img_4} />
                <ol>
                    <li><div className="link-to-mont-saint-michel-day"><Link to="j1"> Journée 1 : 2 Juin 2022 - Le départ de Lomme à Villers-Brûlin </Link></div></li>
                    <li><div className="link-to-mont-saint-michel-day"><Link to="j2"> Journée 2 : Le Pas-de-Calais - Abbeville </Link></div></li>
                    <li><div className="link-to-mont-saint-michel-day"><Link to="j3"> Journée 3 : La Somme - Berneval le Grand</Link></div></li>
                    <li><div className="link-to-mont-saint-michel-day"><Link to="j4"> Journée 4 : Dieppe - Colleville </Link></div></li>
                    <li><div className="link-to-mont-saint-michel-day"><Link to="j5"> Journée 5 : Le pays de Caux - Saint-Jouin-Bruneval </Link></div></li>
                    <li><div className="link-to-mont-saint-michel-day"><Link to="j6"> Journée 6 : L'embouchure de la Seine - Blonville-sur-Mer </Link></div></li>
                    <li><div className="link-to-mont-saint-michel-day"><Link to="j7"> Journée 7 : Le long de l'Orne - Saint-Rémy </Link></div></li>
                    <li><div className="link-to-mont-saint-michel-day"><Link to="j8"> Journée 8 : Suisse Normande - Saint-Hilaire-du-Harcouët</Link></div></li>
                    <li><div className="link-to-mont-saint-michel-day"><Link to="j9"> Journée 9 : Le Mont Saint-Michel </Link></div></li>
                </ol>
            </div>
        );
    }

}