import React from "react";
import { Link } from "react-router-dom";

import "../../styles/theWall.css";
import LinkImaged from "./LinkImaged";

const cyclix_ex_2 = "https://datas.blog.mpaty.fr/images/cyclix_ex_2.jpg";
const memow_ex_1 = "https://datas.blog.mpaty.fr/images/memow_ex_1.jpg";
const danceforscore_ex_1 = "https://datas.blog.mpaty.fr/images/danceforscore_ex_1.png";
const warground_ex_1 = "https://datas.blog.mpaty.fr/images/warground_ex_1.jpg";
const labyrinthepoo2022_ex_2 = "https://datas.blog.mpaty.fr/images/labyrinthepoo2022_ex_2.png";
const montsaintmichel_ex_1 = "https://datas.blog.mpaty.fr/images/montsaintmichel_ex_1.jpg";
const assembler_interpreter_exec = "https://datas.blog.mpaty.fr/images/assemblerinterpreter/verbose_exec.png";

export default class TheWall extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);

    }


    render() {
        return(

            <div id="the-wall">
                <div id="luciole-top-wall-line"></div>
                <div id="top-wall-line"></div>

                <div class="bundle" id="the-wall-second-bundle">
                    <div class="row-1">
                        <LinkImaged class="link-imaged link-imaged-assemblerinterpreter" linkClass="link-imaged-a link-imaged-assemblerinterpreter" linkId="" toPath="/pages/assemblerinterpreter" placeholder="Interpreteur assembleur" imageSrc={assembler_interpreter_exec} title="" alt="" />
                    </div>
                </div>

                <div class="bundle" id="the-wall-front-bundle">
                    <div class="row-1">
                        <LinkImaged class="link-imaged link-imaged-montsaintmichel" linkClass="link-imaged-a link-imaged-montsaintmichel" linkId="" toPath="/pages/montsaintmichel" placeholder="Vélo-rando Mt Saint-Michel" imageSrc={montsaintmichel_ex_1} title="" alt="" />
                    </div>
                    <div class="row-2">
                        <LinkImaged class="link-imaged link-imaged-cyclix-app" linkClass="link-imaged-a link-imaged-cyclix-app" linkId="" toPath="/pages/cyclix" placeholder="Cyclix project" imageSrc={cyclix_ex_2} title="" alt="" />
                        <LinkImaged class="link-imaged link-imaged-memow-app" linkClass="link-imaged-a link-imaged-memow-app" linkId="" toPath="/pages/memow" placeholder="Memow App" imageSrc={memow_ex_1} title="" alt="" />
                    </div>
                </div>

                <div class="bundle" id="the-wall-games-bundle">
                    <div class="row-1">
                        <LinkImaged class="link-imaged link-imaged-labyrinthe-poo-2022" linkClass="link-imaged-a link-imaged-labyrinthe-poo-2022" linkId="" toPath="/pages/labyrinthepoo2022" placeholder="Labyrinthe POO 2022" imageSrc={labyrinthepoo2022_ex_2} title="" alt="" />
                        <LinkImaged class="link-imaged link-imaged-dance-for-score" linkClass="link-imaged-a link-imaged-dance-for-score" linkId="" toPath="/pages/danceforscore" placeholder="Dance For Score" imageSrc={danceforscore_ex_1} title="" alt="" />
                    </div>
                    <div class="row-2">
                        <LinkImaged class="link-imaged link-imaged-warground-game" linkClass="link-imaged-a link-imaged-warground-game" linkId="" toPath="/pages/wargroundgame" placeholder="Warground Game" imageSrc={warground_ex_1} title="" alt="" />
                    </div>
                </div>
            </div>

        );
    }






}


/*
<div className="row-2-links-nequ-container">
</div>


<LinkImaged class="link-imaged mid-line-link" linkClass="link mid-line-link" linkId="" toPath="/pages/danceforscore" placeholder="Dance For Score" imageSrc={danceforscore_ex_1} title="" alt="" />
<LinkImaged class="link-imaged fat-squarelink" linkClass="link fat-squarelink" linkId="" toPath="/pages/labyrinthepoo2022" placeholder="Labyrinthe POO 2022" imageSrc={labyrinthepoo2022_ex_2} title="" alt="" />
<div className="footer-marge"></div>*/