import React from "react";
import { Link } from "react-router-dom";

const img_1 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/1.jpg";
const img_2 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/2.jpg";
const img_3 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/3.jpg";
const img_4 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/4.jpg";
const img_5 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/5.jpg";
const img_6 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/6.jpg";
const img_7 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/7.jpg";
const img_8 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/8.jpg";
const img_9 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/9.jpg";
const img_10 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/10.jpg";
const img_11 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/11.jpg";
const img_12 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/12.jpg";
const img_13 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/13.jpg";
const img_14 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/14.jpg";
const img_15 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/15.jpg";
const img_16 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/16.jpg";
const img_17 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/17.jpg";
const img_18 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/18.jpg";
const img_19 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/19.jpg";
const img_20 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/20.jpg";
const img_21 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/21.jpg";
const img_22 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/22.jpg";
const img_23 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/23.jpg";
const img_24 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/24.jpg";
const img_25 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/25.jpg";
const img_26 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/26.jpg";
const img_27 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/27.jpg";
const img_28 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/28.jpg";
const img_29 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/29.jpg";
const img_30 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/30.jpg";
const img_31 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/31.jpg";
const img_32 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/32.jpg";
const img_33 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/33.jpg";
const img_34 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/34.jpg";
const img_35 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/35.jpg";
const img_36 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/36.jpg";
const img_37 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/37.jpg";
const img_38 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/38.jpg";
const img_39 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/39.jpg";
const img_40 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/40.jpg";
const img_41 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/41.jpg";
const img_42 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/42.jpg";
const img_43 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/43.jpg";
const img_44 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/44.jpg";
const img_45 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/45.jpg";
const img_46 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/46.jpg";
const img_47 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/47.jpg";
const img_48 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/48.jpg";
const img_49 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/49.jpg";
const img_50 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/50.jpg";
const img_51 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/51.jpg";
const img_52 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/52.jpg";
const img_53 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/53.jpg";
const img_54 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/54.jpg";
const img_55 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/55.jpg";
const img_56 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/56.jpg";
const img_57 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/57.jpg";
const img_58 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/58.jpg";
const img_59 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/59.jpg";
const img_60 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/60.jpg";
const img_61 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/61.jpg";
const img_62 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/62.jpg";
const img_63 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/63.jpg";
const img_64 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/64.jpg";
const img_65 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/65.jpg";
const img_66 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/66.jpg";
const img_67 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/67.jpg";
const img_68 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/68.jpg";
const img_69 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/69.jpg";
const img_70 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/70.jpg";
const img_71 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/71.jpg";
const img_72 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/72.jpg";
const img_73 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/73.jpg";
const img_74 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/74.jpg";
const img_75 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/75.jpg";
const img_76 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/76.jpg";
const img_77 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/77.jpg";
const img_78 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/78.jpg";
const img_79 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/79.jpg";
const img_80 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/80.jpg";
const img_81 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/81.jpg";
const img_82 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/82.jpg";
const img_83 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/83.jpg";
const img_84 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/84.jpg";
const img_85 = "https://datas.blog.mpaty.fr/images/montsaintmichel/j5/85.jpg";


export default class Journee5 extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="montsaintmichel-j-page" id="montsaintmichel-j5-page">
                <h1>Lundi - Fécamp {"&"} Etretat</h1>
                <h2>Les falaises</h2>
                <table><tr><th>Distance (km)</th><th>Temps en route</th><th>Horaires</th></tr><tr><td>47.764</td><td>3h04</td><td>10h-18h30</td></tr></table>
                <p>Quoi de mieux que de se réveiller au milieu des étangs avec un beau soleil au dessus de la tête ? Pour déjeuner il faut du pain alors on remonte vers le village et là à la place d'une boulangerie se trouve un distributeur automatique à baguette (la classique et la traditionnelle), on boit le café devant la mairie.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_1} />
                </div>                
                <p>Voilà Fécamp, très belle ville avec ses deux églises, son port et sa plage.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_2} />
                    <img loading="lazy" src={img_3} />
                    <img loading="lazy" src={img_4} />
                    <img loading="lazy" src={img_5} />
                    <img loading="lazy" src={img_6} />
                    <img loading="lazy" src={img_7} />
                    <img loading="lazy" src={img_8} />
                    <img loading="lazy" src={img_9} />
                    <img loading="lazy" src={img_10} />
                    <img loading="lazy" src={img_11} />
                    <img loading="lazy" src={img_12} />
                    <img loading="lazy" src={img_13} />
                    <img loading="lazy" src={img_14} />
                    <img loading="lazy" src={img_15} />
                    <img loading="lazy" src={img_16} />
                    <img loading="lazy" src={img_17} />
                    <img loading="lazy" src={img_18} />
                    <img loading="lazy" src={img_19} />
                    <img loading="lazy" src={img_20} />
                    <img loading="lazy" src={img_21} />
                    <img loading="lazy" src={img_22} />
                    <img loading="lazy" src={img_23} />
                    <img loading="lazy" src={img_24} />
                    <img loading="lazy" src={img_25} />
                    <img loading="lazy" src={img_26} />
                    <img loading="lazy" src={img_27} />
                </div>                
                <p>On mange sur la plage. J'ai pris une glace à la Bénédictine c'est une liqueur de Fécamp. Puis on continue notre route, pour quitter Fécamp on doit monter une belle côte, ça fait les jambes.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_28} />
                    <img loading="lazy" src={img_29} />
                    <img loading="lazy" src={img_30} />
                    <img loading="lazy" src={img_31} />
                    <img loading="lazy" src={img_32} />
                    <img loading="lazy" src={img_33} />
                    <img loading="lazy" src={img_34} />
                    <img loading="lazy" src={img_35} />
                    <img loading="lazy" src={img_36} />
                    <img loading="lazy" src={img_37} />
                    <img loading="lazy" src={img_38} />
                    <img loading="lazy" src={img_39} />
                    <img loading="lazy" src={img_40} />
                </div>                
                <p>Voici Etretat, d'abord le village en amont avec son église et son cimetière.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_41} />
                    <img loading="lazy" src={img_42} />
                    <img loading="lazy" src={img_43} />
                    <img loading="lazy" src={img_44} />
                    <img loading="lazy" src={img_45} />
                    <img loading="lazy" src={img_46} />
                    <img loading="lazy" src={img_47} />
                    <img loading="lazy" src={img_48} />
                    <img loading="lazy" src={img_49} />
                    <img loading="lazy" src={img_50} />
                    <img loading="lazy" src={img_51} />
                    <img loading="lazy" src={img_52} />
                    <img loading="lazy" src={img_53} />
                    <img loading="lazy" src={img_54} />
                    <img loading="lazy" src={img_55} />
                    <img loading="lazy" src={img_56} />
                    <img loading="lazy" src={img_57} />
                    <img loading="lazy" src={img_58} />
                    <img loading="lazy" src={img_59} />
                    <img loading="lazy" src={img_60} />
                    <img loading="lazy" src={img_61} />
                    <img loading="lazy" src={img_62} />
                    <img loading="lazy" src={img_63} />
                    <img loading="lazy" src={img_64} />
                    <img loading="lazy" src={img_65} />
                    <img loading="lazy" src={img_66} />
                </div>                
                <p>Etretat et ses fameuses falaises.</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_67} />
                    <img loading="lazy" src={img_68} />
                    <img loading="lazy" src={img_69} />
                    <img loading="lazy" src={img_70} />
                    <img loading="lazy" src={img_71} />
                    <img loading="lazy" src={img_72} />
                    <img loading="lazy" src={img_73} />
                    <img loading="lazy" src={img_74} />
                    <img loading="lazy" src={img_75} />
                    <img loading="lazy" src={img_76} />
                </div>                
                <p>Direction Saint-Jouin-Bruneval pour le camping !</p>
                <div className="imgs-zone">
                    <img loading="lazy" src={img_77} />
                    <img loading="lazy" src={img_78} />
                    <img loading="lazy" src={img_79} />
                    <img loading="lazy" src={img_80} />
                    <img loading="lazy" src={img_81} />
                    <img loading="lazy" src={img_82} />
                    <img loading="lazy" src={img_83} />
                    <img loading="lazy" src={img_84} />
                    <img loading="lazy" src={img_85} />
                </div>
                <p>Aujourd'hui on s'est rapproché du Havre. Et il faut savoir qu'avant Le Havre il n'y a aucun camping, le dernier c'est celui de Saint-Jouin-Bruneval. Les propriétaires sont sympathiques et sont investis dans leur camping, il a été restaurer et ça se voit, on a même un préau et une table de pique-nique rien qu'à nous.</p>

                <div className="previous-next-days" >
                    <Link to="/pages/montsaintmichel/j4">Jour précédent</Link>
                    <Link to="/pages/montsaintmichel/j6">Jour suivant</Link>
                </div>

            </div>
        );
    }

}