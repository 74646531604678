import React from "react";

import "../../../styles/pages/pages.css";
import "../../../styles/pages/labyrinthepoo2022.css";

const labyrinthepoo2022_jar = "https://datas.blog.mpaty.fr/builds/labyrinthe2022.jar";
const labyrinthepoo2022_ex_1 = "https://datas.blog.mpaty.fr/images/labyrinthepoo2022_ex_1.png";
const labyrinthepoo2022_ex_3 = "https://datas.blog.mpaty.fr/images/labyrinthepoo2022_ex_3.png";
const labyrinthepoo2022_ex_4 = "https://datas.blog.mpaty.fr/images/labyrinthepoo2022_ex_4.png";
const labyrinthepoo2022_sujet = "https://datas.blog.mpaty.fr/documents/labyrinthepoo2022_sujet.pdf";

export default class LabyrinthePOO2022 extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="a-page" id="labyrinthepoo2022-page">
                <div className="left-side">
                    <h2>Installation</h2>
                    <p>Tout d'abord <a href={labyrinthepoo2022_jar} >télécharger l'éxécutable jeu.jar</a></p>
                    <p>Qu'importe votre OS, ce qui compte c'est que JavaVM soit installée sur votre machine.</p>
                    <p>Plusieurs manières d'éxécuter le jeu, soit directement en double cliquant sur <strong>labyrinthe2022.jar</strong></p>
                    <p>Soit dans la console avec la commande <strong>java -jar labyrinthe2022.jar</strong></p>
                    <h2>Commandes</h2>
                    <p>Après le lancement du jeu vous devez spécifier la taille du labyrinthe {"(largeur et hauteur)"}, puis son type : soit "thin" soit "chess"</p>
                    <p><strong>help</strong> : afficher l'aide sur les commandes.</p>
                    <p><strong>quit</strong> : quitter le jeu.</p>
                </div>
                <div className="main-container">
                    <h1>Jeu Labyrinthe</h1>
                    <h3>Projet Java en POO, 2022 en Licence 2ème année.</h3>
                    <h3>8 semaines.</h3>
                    <h4>Déroulement</h4>
                    <p>
                        <ul>
                            <li>Modélisation du labyrinthe.</li>
                            <li>Objets et personnages.</li>
                            <li>Les Actions</li>
                            <li>Modélisation finale.</li>
                        </ul>
                    </p>
                    <h4>Présentation</h4>
                    <p>
                        Le jeu se déroule dans un labyrinthe généré aléatoirement selon 2 algorithmes différents pour chacun des labyrinthes : Chesstype et Thintype.
                    </p>
                    <p>
                        Le héros est coincé dans le labyrinthe et doit s'en échapper. Pour cela il peut, à chaque tour, effectuer des actions comme parler à un PNJ, ramasser un objet, vendre, acheter, etc. Chaque PNJ à sa particularité {"(classe)"}, certains d'entres eux pose des énigmes et donnent des indices sur la sortie du labyrinthe d'autres au contraire fournissent une fausse piste.
                    </p>
                    <p>
                        Nous étions 4 {"(Tony, Pol, Thomas et moi)"} pour réaliser un jeu dans la console, avec Java.
                        L'objectif était d'abord d'imaginer la structure du projet en créant le diagramme UML ci dessous.
                        Le travail de groupe nécessite de l'écoute mais aussi une bonne communication pour discuter des idées de chacun et en tirer le meilleur.                        
                    </p>
                    <img loading="lazy" src={labyrinthepoo2022_ex_4} />

                    <h4>Distribution des tâches</h4>
                    <p>
                        Les tâches ont été réparties suivant les classes et leurs héritières, généralement. Ma part a été la création des entités et par héritage des items, je me suis aussi charger de la classe Game contenant la mise en relation de l'interface utilisateur et le fonctionnement global du jeu. Pour me simplifier la vie j'ai créé une classe Displayer qui reçoit les données du jeu et les affiches comme suit :
                        <ul>
                            <li>En haut un "header" contenant le numéro du tour.</li>
                            <li>À gauche le labyrinthe avec le héro, les pnjs et les objets. Ils sont représentés par des caractères unicodes</li>
                            <li>À droite les informations générales soit ce qui se trouve sur la case du héro. Ceci n'a pas été implémenté mais l'idée est de rajouter la vie, l'énergie et l'inventaire du héro.</li>
                            <li>en bas les interactions du héro</li>
                        </ul>
                    </p>
                    <h3>Exemple du Chess type</h3>
                    <img loading="lazy" src={labyrinthepoo2022_ex_1} />
                    <h3>Exemple du Thin type</h3>
                    <img loading="lazy" src={labyrinthepoo2022_ex_3} />
                    <h4>Autres informations sur le projets :</h4>
                    <a href={labyrinthepoo2022_sujet}>Sujet du projet {"(pdf)"}</a>
                </div>

            </div>
        );
    }

}