import "./styles/main.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./component/interface/Home.jsx";
import TheWall from "./component/interface/TheWall.jsx";
import Error404 from "./component/error/Error404.jsx";
import React from "react";
import Root from "./component/Root.jsx";
import Cyclix from "./component/pages/cyclix/Cyclix.jsx";
import Memow from "./component/pages/memow/Memow.jsx";
import MontSaintMichel from "./component/pages/mont_saint_michel/MontSaintMichel.jsx";
import DanceForScore from "./component/pages/jeux/DanceForScore.jsx";
import Warground from "./component/pages/jeux/Warground.jsx";
import LabyrinthePOO2022 from "./component/pages/jeux/LabyrinthePOO2022.jsx";
import AssemblerInterpreter from "./component/pages/asm_interpreter/AssemblerInterpreter.jsx";
import Contact from "./component/pages/Contact";
import Journee1 from "./component/pages/mont_saint_michel/Journee1";
import Journee2 from "./component/pages/mont_saint_michel/Journee2";
import Journee3 from "./component/pages/mont_saint_michel/Journee3";
import Journee4 from "./component/pages/mont_saint_michel/Journee4";
import Journee5 from "./component/pages/mont_saint_michel/Journee5";
import Journee6 from "./component/pages/mont_saint_michel/Journee6";
import Journee7 from "./component/pages/mont_saint_michel/Journee7";
import Journee8 from "./component/pages/mont_saint_michel/Journee8";
import Journee9 from "./component/pages/mont_saint_michel/Journee9";
import LicenceInfo from "./component/pages/etudes/licence_info/LicenceInfo";
import Licence1 from "./component/pages/etudes/licence_info/l1/Licence1";
import Licence2 from "./component/pages/etudes/licence_info/l2/Licence2";
import Licence3 from "./component/pages/etudes/licence_info/l3/Licence3";

const data_path = "https://datas.blog.mpaty.fr/";

export default class App extends React.Component {

    constructor(props) {
      super(props);


    }

    render() {

      return(
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Root />}>
                        <Route index element={<Home />} />
                        <Route path="home" element={<Home />} />
                        <Route path="pages" element={<TheWall />} />
                        <Route path="contact" element={<Contact />} />
                        <Route path="pages/cyclix" element={<Cyclix />} />
                        <Route path="pages/memow" element={<Memow />} />
                        <Route path="pages/montsaintmichel" element={<MontSaintMichel />} />
                        <Route path="pages/montsaintmichel/j1" element={<Journee1 />} />
                        <Route path="pages/montsaintmichel/j2" element={<Journee2 />} />
                        <Route path="pages/montsaintmichel/j3" element={<Journee3 />} />
                        <Route path="pages/montsaintmichel/j4" element={<Journee4 />} />
                        <Route path="pages/montsaintmichel/j5" element={<Journee5 />} />
                        <Route path="pages/montsaintmichel/j6" element={<Journee6 />} />
                        <Route path="pages/montsaintmichel/j7" element={<Journee7 />} />
                        <Route path="pages/montsaintmichel/j8" element={<Journee8 />} />
                        <Route path="pages/montsaintmichel/j9" element={<Journee9 />} />
                        <Route path="pages/danceforscore" element={<DanceForScore />} />
                        <Route path="pages/wargroundgame" element={<Warground />} />
                        <Route path="pages/labyrinthepoo2022" element={<LabyrinthePOO2022 />} />
                        <Route path="pages/assemblerinterpreter" element={<AssemblerInterpreter />} />
                        <Route path="pages/etudes/licence" element={<LicenceInfo />} />
                        <Route path="pages/etudes/licence/l1" element={<Licence1 />} />
                        <Route path="pages/etudes/licence/l2" element={<Licence2 />} />
                        <Route path="pages/etudes/licence/l3" element={<Licence3 />} />
                        <Route path="*" element={<Error404 />} />
                    </Route>
                </Routes>
            </BrowserRouter>
      );
    }

}



