import React from "react";

import "../../../styles/pages/pages.css";
import "../../../styles/pages/wargroundgame.css";

const wargroundgame_archive = "https://datas.blog.mpaty.fr/builds/Warground.rar";
const warground_ex_1 = "https://datas.blog.mpaty.fr/images/warground_ex_1.jpg";
const warground_ex_2 = "https://datas.blog.mpaty.fr/images/warground_ex_2.jpg";
const warground_ex_3 = "https://datas.blog.mpaty.fr/images/warground_ex_3.jpg";

export default class Warground extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="a-page" id="wargroundgame-page">
                <div className="left-side">
                    <h2>Installation</h2>
                    <p>Tout d'abord <a href={wargroundgame_archive} >télécharger l'archive du jeu.</a></p>
                    <p>Décompresser l'archive {"(avec winrar)"}.</p>
                    <p>Lancer <strong>Warground.exe</strong></p>
                    <h2>Commandes</h2>
                    <p><strong>F</strong> : activer/désactiver le plein écran.</p>
                    <p><strong>Z, Q, S, D</strong> : Déplacements / Tirs</p>
                    <p><strong>Espace</strong> : Changer le mode du char.</p>

                </div>
                <div className="main-container">
                    <h1>Warground Game Jam Gamecodeur</h1>
                    <a href="https://blackhole59.itch.io/warground" > {">>>"} voir page itch.io</a>
                    <p>
                        Bonjour ! j'ai passé beaucoup de temps (15 jours a peu près ) sur ce jeu et malheureusement je ne m'en sors plus avec les bugs (de plus le code est très sale, mais c'est normal!) ayant été trop ambitieux j'ai eu du mal avec l'IA qui ne marche même pas ;(.
                    </p>
                    <p>
                        A ceux qui téléchargeront : Merci sa me fais très plaisir, vous pourrez découvrir 3 petites maps où se déplaceront vos Tanks! (choisie depuis votre deck), ils tirent mais sur des ennemies visiblement immobile et pas très hostile... Bonne découverte ! (SI VOUS RENCONTREZ UN PROBLÈME DE PLEINE ECRAN APPUYER SUR F POUR REVENIR EN FENÊTRER)
                    </p>
                    <p>
                        informations: élève MaTTh5913 depuis le 05/05/2018  a utiliser MonoGame en C#, code, graphismes et musique par moi ; sons depuis universal-soundbank.com ; pas de librairies externe ; temps passé environ 15 jours bien remplies ; 
                    </p>
                    <p>
                        Avis: Pour ma première GameJam je suis fière d'avoir passé du temps sur ce projet malgré les difficultés rencontrées j'ai appris beaucoup de choses et pour moi c'est une réussite. 
                    </p>
                    <img loading="lazy" src={warground_ex_1} />
                    <img loading="lazy" src={warground_ex_2} />
                    <img loading="lazy" src={warground_ex_3} />
                    
                </div>

            </div>
        );
    }

}