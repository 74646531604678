import React from "react";
import { Link } from "react-router-dom";

import "../../../../../styles/pages/licenceInfo.css";

const tps_tw_2 = "https://datas.blog.mpaty.fr/projets/tps/tps-tw-2.rar";
const l2_cdc = "https://datas.blog.mpaty.fr/projets/tps/l2-cdc.rar";

export default class Licence2 extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="licence-info" id="licence-info-l2">
                <div id="licence-info-header">
                    <div className="licence-info-retour-btn"><Link to="/pages/etudes/licence">{"<"}</Link></div>
                    <h1>Licence Informatique</h1>
                    <h2>Université de Lille</h2>
                    <h2>Année 2 {"(2021-2022)"}</h2>
                </div>
                <br/>
                <div id="left-side">
                    <h2>Composantes</h2>
                    <h3>semestre 3</h3>
                    <ul>
                        <li><a href="#l2s3-alr">Automates & Langages réguliers</a></li>
                        <li><a href="#l2s3-poo">Prorammation Orientée Objet</a></li>
                        <li><a href="#l2s3-logi">Logique Propositionnelle</a></li>
                        <li><a href="#l2s3-UNIX">Unix, Shell & Langage de scripts</a></li>
                        <li><a href="#l2s3-bdd">Base de donnée 1</a></li>
                        <li><a href="#l2s3-tweb2">Technologie du web 2</a></li>
                    </ul>
                    <h3>semestre 4</h3>
                    <ul>
                        <li><a href="#l2s4-asd">Algorithmes et structures de données</a></li>
                        <li><a href="#l2s4-cdc">Compression de l'information, détection et correction d'erreurs</a></li>
                        <li><a href="#l2s4-mpc-bpc">Bases et Maîtrise de la programmation en C</a></li>
                        <li><a href="#l2s3-poo">Projet de groupe en Java</a></li>
                        <li><a href="#l2s3-bdd">Base de données 2</a></li>
                        <li><a href="#l2s4-js">ReactJS</a></li>
                    </ul>
                </div>
                <div id="main-container">
                    <h2>Semestre 3</h2>
                    <h3 id="l2s3-alr">Automates & Langages réguliers</h3>
                    <p>Regex ou expressions régulières, & Automates.</p>
                    
                    <h3 id="l2s3-poo">Programmation Orientée Objet & Projet</h3>
                    <p>Principe de POO en Java et projet de groupe voir <Link to="/pages/labyrinthepoo2022">ici</Link></p>
                    
                    <h3 id="l2s3-logi">Logique Propositionnelle</h3>
                    <p>Formules logiques, syntaxe et sémantique. Satisfaisabilité d'un programme, vérification par induction et récursion.</p>
                    
                    <h3 id="l2s3-UNIX">Unix, Shell & Langage de scripts</h3>
                    
                    <h3 id="l2s3-bdd">Base de données 1</h3>
                    <p>Conception à partir d'une situation puis construction avec postgreSQL</p>
                    
                    <h3 id="l2s3-tweb2">Technologie du web</h3>
                    <p>Introduction à PHP et création de serveur, manipulation de données au format JSON.</p>
                    <p><a href={tps_tw_2}>Toutes les séances de tps</a></p>
                    

                    <h2>Semestre 4</h2>
                    <h3 id="l2s4-asd">Algorithmes et structures de données</h3>
                    <p>Comptage d'opérations, Pire et Meilleurs des cas et Complexités d'algorithmes récursifs et de tries.</p>
                    <p>Tables de hachage, Pile, File et Arbres binaires de recherche.</p>
                    
                    <h3 id="l2s4-cdc">Compression de l'information, détection et correction d'erreurs</h3>
                    <p>source et quantité d'informations, entropie de la source et son codage optimal {"(Huffman)"}.</p>
                    <p>Compression, notion de canal bruité.</p>
                    <p>Codage détecteurs d'erreurs : bit de parité, répétition</p>
                    <p>Codage correcteurs d'erreurs : codage de Hamming.</p>
                    <p><a href={l2_cdc}></a></p>

                    <h3 id="l2s4-mpc-bpc">Bases et Maîtrise de la programmation en C</h3>
                    <p>Bases du langages, pointeurs, constructeurs de type, compilation séparée & mémoire dynamique.</p>
                    
                    <h3 id="l2s4-js">ReactJS</h3>
                    <p>Application avec ReactJS, divers jeux et projets au cours des tps.</p>

                </div>
            </div>
        );
    }

}