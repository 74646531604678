import React from "react";
import { Link } from "react-router-dom";

import "../../styles/linkImaged.css";

export default class LinkImaged extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div className={this.props.class}>
                <img loading="lazy" src={this.props.imageSrc} title={this.props.title} alt={this.props.alt} />
                <Link className={this.props.linkClass} id={this.props.linkId} to={this.props.toPath}>{this.props.placeholder}</Link>
            </div>
        );
    }


}
