import React from "react";

import "../../../styles/pages/pages.css";
import "../../../styles/pages/memow.css";

const memow_accueil = "https://datas.blog.mpaty.fr/images/memow_ex_1.jpg";

const build_1_2_2_05_01_2023 = "https://datas.blog.mpaty.fr/builds/memow_app_1.2.2_05_01_2023.apk";
const build_1_2_1_04_01_2023 = "https://datas.blog.mpaty.fr/builds/memow_app_1.2.1_04_01_2023.apk";
const build_1_1_13_11_2022 = "https://datas.blog.mpaty.fr/builds/memow_app_1.1_13_11_2022.apk";
const build_1_0_12_11_2022 = "https://datas.blog.mpaty.fr/builds/memow_app_1.0_12_11_2022.apk";


export default class Memow extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <div className="a-page" id="memow-page">
                <h1>Memow</h1>
                <br/>
                <div id="left-side">
                    <h2>Historique des builds</h2>
                    <div className="a-build">
                        <div>
                            <div className="date">05_01_2023</div>
                            <div className="plateform">Android</div>
                        </div>
                        <div className="name">Memow 1.2.2</div>
                        <div className="link"><a href={build_1_2_2_05_01_2023}>APK</a></div>
                    </div>
                    <div className="a-build">
                        <div>
                            <div className="date">04_01_2023</div>
                            <div className="plateform">Android</div>
                        </div>
                        <div className="name">Memow 1.2.1</div>
                        <div className="link"><a href={build_1_2_1_04_01_2023}>APK</a></div>
                    </div>
                    <div className="a-build">
                        <div>
                            <div className="date">13_11_2022</div>
                            <div className="plateform">Android</div>
                        </div>
                        <div className="name">Memow 1.1</div>
                        <div className="link"><a href={build_1_1_13_11_2022}>APK</a></div>
                    </div>
                    <div className="a-build">
                        <div>
                            <div className="date">12_11_2022</div>
                            <div className="plateform">Android</div>
                        </div>
                        <div className="name">Memow 1.0</div>
                        <div className="link"><a href={build_1_0_12_11_2022}>APK</a></div>
                    </div>
                </div>
                <div id="main-container">
                    <h3>Une nouvelle façon de bloc-noter.</h3>
                    <img loading="lazy" src={memow_accueil}></img>
                    <a href="https://memow.mpaty.fr/" >Lien vers le site web.</a>

                </div>
            </div>
        );
    }

}